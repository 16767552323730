<style scoped lang="less">
  .text {
    display: flex;
    align-items: center;
    .name {
      color: #7F7F7F;
      font-size: 12px;
    }
    .value {
      margin-left: 19px;
      font-size: 18px;
      span {
        font-size: 12px;
      }
    }
  }
  .bar {
    margin-top: 2px;
    position: relative;
    height: 6px;
    background: #E8E8E8;
    border-radius: 11px;
    .value {
      border-radius: 11px;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
    }
  }
</style>

<template>
  <div class="progress-bar">
    <div class="text">
      <div class="name">{{label}}</div>
      <div class="value theme-text-color">{{value}} <span>%</span> </div>
    </div>
    <div class="bar">
      <div class="value theme-bg-color" :style="{width: value + '%'}"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: { type: Number, value: 0 },
    label: { type: String, default: '资料总进度' },
  }
}
</script>
